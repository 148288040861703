.search-container {
  text-align: center;
  margin-bottom: 1rem;
}

.search-container input {
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #2c3e50;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  background: #2c3e50;
  color: white;
}

.search-container input:focus {
  border-color: #0b6e73;
}

.search-container input::placeholder {
  color: white;
  font-weight: bold;
}

.rules-highlight {
  background-color: #29868a;
}

.rules-header {
  text-align: center;
  color: #fff;
  margin-top: 2rem;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.content-section {
  display: flex;
  padding: 0 2rem 1rem 2rem;
  background-color: #0c1422;
  color: #fff;
  gap: 1rem;
  height: 67vh;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.violation {
  margin-left: auto;
  padding-left: 20px;
}

.sections-menu {
  width: 25%;
}

.section-content {
  width: 75%;
  padding-left: 1rem;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #108e93 #0c1422;
}

.section-btn {
  background-color: #0a101a;
  color: white;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 1rem;
  display: block;
}

.sections-menu select {
  display: none;
}

.section-btn:hover,
.section-btn.active {
  background-color: #0b6e73;
}

.section-details {
  display: none;
  padding: 13px 13px 0.5px 13px;
  border-radius: 13px;
}

.section-details.active {
  display: block;
  background: #0a101a;
}

.section-block {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #0b121d;
  color: #dfdfdf;
  border-radius: 1%;
}

.section-block p {
  margin-top: 0;
}

.section-details strong {
  font-size: 2.2rem;
  display: block;
  margin-bottom: 1rem;
}

.update-date {
  font-size: 14px;
  color: #aaaaaab7;
  text-align: center;
  user-select: none;
  padding-right: 45px;
}

.date {
  color: #f39c12;
  font-weight: bold;
}

.min-warning {
  color: #eccc3e;
}

.max-warning {
  color: #f23d4c;
}

.highlight-gray {
  background-color: #ffffff56;
  border-radius: 3px;
  padding: 0 2px;
  font-weight: bold;
}

.highlight-link {
  font-weight: bold;
  color: #108e93;
  text-decoration: underline;
}

.notice {
  display: inline-block;
  background-color: #2c3e50;
  padding: 7px;
  border-radius: 5px;
  color: #ecf0f1;
  font-weight: bold;
}

.parentheses-background {
  position: relative;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 0 2px;
}

.group-limits-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  color: white;
}

.group-limits-table th,
.group-limits-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.group-limits-table th {
  background-color: #2c3e50;
  color: white;
}

.group-limits-table td {
  background-color: #1e2a38;
}

.history {
  background-color: #1c2739;
  border: 1px solid #324a5e;
  border-radius: 5px;
  padding: 15px;
  margin: 20px auto;
  width: 70%;
  color: #ffffff;
  text-align: center;
}

.history h3 {
  margin-top: 0;
  color: #d1d5db;
}

.history ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  text-align: left;
}

.history li {
  padding: 8px;
  border-bottom: 1px solid #324a5e;
  color: #d1d5db;
}

.history li:last-child {
  border-bottom: none;
}

.history li strong {
  color: #f39c12;
}

.bold-text {
  font-weight: bold;
  color: #f39c12;
}

@media (max-width: 768px) {
  .content-section {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  .sections-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section-content {
    width: 100%;
    padding: 0 1rem;
  }

  .search-container {
    width: 80%;
  }

  .section-btn {
    display: none;
  }

  .sections-menu select {
    display: block;
    width: 80%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    background-color: #0a101a;
    color: white;
    margin-bottom: 1rem;
    cursor: pointer;
    border-radius: 5px;
  }

  .sections-menu select:focus {
    outline: none;
  }

  .update-date {
    padding-left: 45px;
  }
}
