.not-found {
  text-align: center;
  margin-top: 20px;
}

.not-found h1 {
  font-size: 2.5rem;
  color: #cacaca;
}

.not-found p {
  font-size: 1.2rem;
  color: #666;
}

.not-found a:hover {
  color: #208cff;
}

.game-area {
  position: relative;
  width: 90vw;
  height: 40vh;
  margin: 10px auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.dot {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #108e93;
  border-radius: 50%;
  cursor: pointer;
  transition: top 0.1s ease, left 0.1s ease;
}

.game-over {
  margin-top: 20px;
}

.game-over p {
  font-size: 1.2rem;
  color: #ff0000;
}

@media (max-width: 600px) {
  .not-found h1 {
    font-size: 2rem;
  }

  .not-found p {
    font-size: 1rem;
  }

  .game-area {
    width: 80vw;
    height: 35vh;
  }

  .dot {
    width: 30px;
    height: 30px;
  }
}
