.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.meetingspage-section {
  flex: 1;
  color: white;
  padding: 1rem 1rem;
  text-align: center;
}

.meetingspage-container {
  width: 90%;
  background-color: #0a101a;
  border-radius: 1vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 2s ease-in-out;
}

.meetingspage-text {
  text-align: center;
  margin-bottom: 18px;
}

.meetingspage-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.meetings-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  width: 100%;
}

.meeting-item {
  border-radius: 1vh;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  width: 40vh;
  height: 22.5vh;
}

.meeting-item:hover {
  transform: scale(1.05);
}

.meeting-thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.meeting-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.meeting-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.29);
  color: #ffffff;
  padding: 0.5rem;
  text-align: left;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.meeting-title,
.meeting-date {
  margin: 0;
}

.meeting-title {
  font-weight: bold;
  margin: 0;
}

@media (max-width: 767px) {
  .meetings-grid {
    display: none;
  }

  .small-screen-message {
    display: block;
    color: #ffffff;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 2rem;
    background-color: #ff0000;
  }
}

.small-screen-message {
  display: none;
  background-color: #00ff00;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.95);
  animation: fadeIn 0.5s ease-in-out;
}

.modal-content {
  position: relative;
  background-color: #0a101a;
  margin: auto;
  padding: 20px;
  border: none;
  width: 80%;
  max-width: 700px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.5s ease-in-out;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: black;
  border-radius: 8px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
