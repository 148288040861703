.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.aboutpage-section {
    flex: 1;
}

.aboutpage-section {
    color: white;
    padding: 1rem 1rem;
    text-align: center;
}

.aboutpage-container {
    width: 90%;
    background-color: #0a101a;
    border-radius: 3vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 2s ease-in-out;
}

.aboutpage-text {
    text-align: center;
    margin-bottom: 18px;
}

.aboutpage-text h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.aboutpage-text p {
    padding-left: 40px;
    padding-right: 40px;
}

.join-button {
    background-color: #108e93;
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.15rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out;
    margin-bottom: 1.5rem;
}

.join-button:hover {
    background-color: #0b6e73;
}

.aboutpage-highlight {
    color: #108e93;
}

@media (max-width: 768px) {
    .aboutpage-text h2 {
        font-size: 2rem;
    }

    .aboutpage-text p {
        padding-left: 20px;
        padding-right: 20px;
    }

    .join-button {
        font-size: 1rem;
        padding: 0.75rem 1.5rem;
    }
}
