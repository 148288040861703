/* Import Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

/* Keyframes */
@keyframes buttonHoverAnimation {
  0% {
    background-color: #108e93;
    color: #fff;
  }
  100% {
    background-color: #0b6e73;
    color: #fff;
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes approach {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-10px) scale(1.1);
  }
}

@keyframes slideInAndBounce {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  100%,
  0% {
    animation: approach 2s ease-in-out infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes wave {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

/* Global Styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  scrollbar-width: thin;
  scrollbar-color: #108e93 #0c1422;
}

body {
  overflow-x: hidden;
  line-height: 1.6;
  color: #333;
  background-color: #0c1422;
}

button,
.apply-button,
.read-more-button,
.apply-now-button {
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  background-color: #108e93;
  color: #fff;
}

button::before,
.apply-button::before,
.read-more-button::before,
.apply-now-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  transition: transform 0.8s ease-in-out;
  transform: skewX(-25deg) translateX(-100%);
  z-index: 0;
}

button:hover::before,
.apply-button:hover::before,
.read-more-button:hover::before,
.apply-now-button:hover::before {
  transform: skewX(-25deg) translateX(100%);
}

button:hover,
.apply-button:hover,
.read-more-button:hover,
.apply-now-button:hover {
  background-color: #0b6e73;
  transform: scale(1.05);
  border-radius: 5px;
}

button,
.apply-button,
.read-more-button,
.apply-now-button {
  z-index: 1;
  position: relative;
}

*,
*::before,
*::after {
  box-sizing: content-box;
}

::selection {
  background-color: #108e93;
}

a {
  font-weight: bold;
  color: #108e93;
  text-decoration: underline;
}

b {
  color: #108e93;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #0c1422;
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #108e93;
  border-radius: 8px;
  border: 2px solid #0c1422;
}

/* Component Styles */
.scroll-animation {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.scroll-animation.show {
  opacity: 1;
  transform: translateX(0);
}

.main-content,
.about-section,
.rules-section,
.faq-section,
.footer-section {
  padding: 1rem;
}

.main-content h1,
.main-content p {
  animation: slideIn 2s ease-in-out;
}

.faq-section.scroll-animation.show {
  animation: 3s ease-out 0s 1 both running;
  animation-name: slideInFromLeft;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
}

.header-section {
  animation: fadeIn 2s ease-in-out;
  position: relative;
  color: #fff;
  padding: 0;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: #0b121d;
  margin: 0;
  border: none;
}

.header-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  background: url("./assets/background.png") no-repeat center center;
  background-size: cover;
  filter: blur(1px);
  z-index: -2;
  background-color: #0b121d;
}

.header-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 18, 29, 0.9);
  z-index: -1;
}

/* Navigation */
nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1001;
  padding: 1rem 0;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.nav-left {
  display: flex;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 1.5rem;
  display: flex;
  align-items: center;
  animation: slideInLeft 3s ease-in-out;
}

.nav-links a {
  color: #c7c7c7;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  align-items: center;
  padding: 1rem;
  width: 100%;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 9px;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.nav-links a:hover {
  color: #108e93;
  border-color: #108e93;
}

.logo-item a:hover {
  border-color: transparent;
}

.nav-image {
  height: 80px;
  border: none;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.nav-image:hover {
  transform: scale(1.1);
}

.menu-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  color: #c7c7c7;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem 1rem;
  z-index: 1;
  position: relative;
  height: 45vh;
}

/* Social Links */
.social-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 5vh;
  animation: slideIn 2s ease-in-out;
}

.social-links a {
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0.5rem;
  transition: color 0.3s ease-in-out;
}

.social-icon {
  width: 25px;
  height: 25px;
  transition: none;
}

.social-links a:hover .social-icon,
.footer-social-links a:hover .social-icon,
.footer-link:hover .footer-image {
  filter: brightness(0) saturate(100%) invert(35%) sepia(90%) saturate(1115%) hue-rotate(151deg) brightness(91%) contrast(87%);
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
}

.main-content h1 {
  font-size: 3.5rem;
  margin-bottom: 5px;
}

.main-content p {
  font-size: 1.25rem;
}

/* Buttons */
.button-group {
  display: flex;
  gap: 50px;
}

.about-button-group {
  display: flex;
  gap: 10px;
}

button,
.apply-button,
.rules-button,
.read-more-button,
.apply-now-button {
  animation: buttonAnimation 1s infinite;
  animation-timing-function: ease-in-out;
}

.apply-button,
.rules-button {
  background-color: #108e93;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  margin-top: 1rem;
  margin-left: -40px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  animation: slideIn 2s ease-in-out;
}

.apply-button:hover {
  background-color: #0b6e73;
}

.apply-button .button-icon,
.rules-button .button-icon {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-right: 4px; 
}

.apply-button,
.rules-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.apply-button span,
.rules-button span {
  display: inline-flex;
  align-items: center;
}

.join-button {
  background-color: #108e93;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  animation: slideIn 2s ease-in-out;
}

/* Scroll */
.scroll-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;
  margin-top: 100px;
  padding: 1rem 0;
  margin-left: -30px;
}

.arrow {
  font-size: 2rem;
  color: #c9c9c9;
  animation: slideIn 2s ease-in-out, approach 2s ease-in-out 2s infinite;
  cursor: pointer;
}

.scroll-text {
  color: #969696;
  margin-top: 0.2rem;
  font-size: 0.8rem;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  animation: slideIn 2s ease-in-out;
}

.next-section {
  padding: 2rem;
  background-color: #0c1422;
}

/* About Section */
.about-section {
  position: relative;
  overflow: hidden;
  background: #0c1422;
  color: white;
  padding: 5rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  gap: 2rem;
  position: relative;
}

.about-text {
  flex: 1;
  text-align: left;
  max-width: 55%;
}

.about-text h2 {
  font-size: 2.3rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  line-height: 1.1;
}

.about-text p {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.highlight {
  color: #108e93;
}

.read-more-button {
  background-color: transparent;
  color: #fff;
  border: 2px solid #108e93;
  padding: .7rem 2rem;
  font-size: 1.15rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.read-more-button:hover {
  color: #fff;
  background-color: #0b6e73;
}

.about-line {
  width: 150px;
  height: 2px;
  background-color: #108e93;
  margin-bottom: 1rem;
}

.about {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.about-img {
  position: relative;
  flex: 1;
  width: 100%;
  height: 550px;
  overflow: hidden;
}

.about-pic {
  position: absolute;
  top: 0;
  left: 150px;
  height: 100%;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.about-pic.active {
  opacity: 1;
}

/* Rules Section */
.rules-section {
  color: white;
  padding: 5rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rules-container {
  width: 90%;
  background-color: #0a121f;
  border-radius: 7px;
  margin: 0 auto;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 2s ease-in-out;
}

.rules-text {
  text-align: center;
  margin-bottom: 18px;
}

.rules-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.rules-section p {
  padding-left: 40px;
  padding-right: 40px;
}

.apply-now-button {
  background-color: #108e93;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.15rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 1.5rem;
}

.apply-now-button:hover {
  background-color: #0b6e73;
}

.rules-button-group {
  display: flex;
  gap: 1vh;
  justify-content: center;
}

.rules-button-group .button-icon {
  width: 16px;
  height: 16px;
}

/* FAQ Section */
.faq-section {
  color: #fff;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.faq-section.show {
  opacity: 1;
  transform: translateX(0);
}

.faq-item {
  width: 100%;
  max-width: 800px;
  margin: 0.33rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0a121f;
  border-radius: 7px;
  border-left: 3px solid transparent;
  transition: background-color 0.3s ease, border-left-color 0.3s ease;
}

.faq-item.active {
  background-color: #0b121d;
  border-left-color: #108e93;
}

.faq-question {
  background-color: transparent;
  color: #fff;
  padding: 10px;
  padding-left: 25px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  position: relative;
  font-size: 1.17rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  border-left: 2px solid transparent;
}

.plus-icon {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
  font-size: 2rem;
  margin-right: 20px;
}

.faq-question.active .plus-icon {
  transform: rotate(45deg);
}

.faq-question.active {
  background-color: #0b6e73;
  transform: scale(1.1);
  border-radius: 5px;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
  padding: 0 20px;
}

.faq-item.active .faq-answer {
  max-height: 1000px;
  padding: 10px 20px;
}

/* Other Styles */
.connect {
  background-color: #0b6e73;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
  position: relative;
}

.tooltip {
  position: absolute;
  background-color: #0b6e73;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: none;
  z-index: 1000;
  pointer-events: none;
  font-size: 12px;
}

.tooltip.show {
  display: block;
}

/* Footer Styles */
.footer-section {
  background: #0b121d;
  color: #fff;
  position: relative;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-social-links {
  display: flex;
  gap: 1.5rem;
}

.footer-social-links a {
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-social-links .social-icon {
  width: 25px;
  height: 25px;
  fill: currentColor; 
  transition: fill 0.3s ease;
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.footer-link {
  display: flex;
  align-items: center;
}

.footer-image {
  height: 25px;
  margin-left: 5px;
  transition: fill 0.3s ease;
}

@media (max-width: 1080px) and (min-width: 769px) {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .main-content h1 {
    font-size: 2.5rem;
  }

  .main-content p {
    font-size: 1.1rem;
    line-height: 1.5;
    width: 80%;
  }

  .social-links {
    left: 10px;
  }
}

.nav-logo {
  display: none;
}

.about-description p:first-of-type {
  display: block;
}

@media (max-width: 768px) {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .social-links {
    display: none;
  }

  .about-text p:first-of-type {
    display: none !important;
  }

  .rules-section {
    padding: 0 0;
  }

  .rules-section p {
    display: none;
  }

  .header-section::before {
    height: 100%;
  }

  .next-section {
    padding: 0;
  }

  .main {
    height: 50vh;
  }

  .menu-icon {
    display: block;
    font-size: 2rem;
    cursor: pointer;
    color: #c7c7c7;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .apply-button,
  .scroll-div {
    margin-left: 0;
  }

  .apply-button,
  .rules-button {
    padding: 0.7rem 3rem;
    font-size: 1rem;
  }

  .apply-button i,
  .rules-button i {
    display: none;
  }

  .scroll-div {
    margin-top: 0;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #0b121d;
    position: absolute;
    top: 75px;
    left: 0;
    padding: 1rem 0;
    z-index: 1002;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    align-items: center;
  }

  .nav-links.show {
    display: flex;
  }

  .nav-links li {
    margin: 0.5rem 0;
  }

  .nav-links a {
    justify-content: center;
    text-align: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #108e93;
    color: #c7c7c7;
    width: 100%;
  }

  .nav-links a:hover {
    background-color: #0b121d;
  }

  .nav-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    position: relative;
    padding: 10px 1rem;
  }

  .nav-left .nav-logo {
    display: block;
  }

  .nav-left .nav-image {
    display: block;
    height: 50px;
    margin-left: 10px;
  }

  .main-content {
    text-align: center;
    padding: 1rem;
  }

  .main-content h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .main-content p {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .about-container {
    flex-direction: column;
    text-align: center;
  }

  .about-text {
    max-width: 100%;
    flex: 1 1;
    text-align: center;
  }

  .about-pic {
    display: none;
  }

  .social-links {
    position: static;
    flex-direction: row;
    margin-top: 1rem;
  }

  .social-links a {
    transform: none;
    writing-mode: horizontal-tb;
    padding-bottom: 0;
    margin-right: 1rem;
  }

  .logo-item {
    order: 0;
  }

  .apply-now-button {
    /* width: 100%; */
    max-width: 200px;
    padding: 0.7rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    margin: 0.5rem auto;
  }

  .rules-button-group {
    /* flex-direction: column; */
    gap: 10px;
  }

  .rules-section p {
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-copy {
    margin-top: 1rem;
    margin-left: 0;
  }

  .footer-logo {
    position: static;
    margin-top: 1rem;
  }
}

body.nav-open {
  overflow: hidden;
}

.show {
  display: flex !important;
}

/* Notifications */
.notification {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #108e93;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.notification.show {
  opacity: 1;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

footer {
  margin-top: auto;
}
